<template>
  <div class="bg-white pb-5">
    <div class="d-none d-md-flex">
      <HeroCurved/>
    </div>
    <div class="main-container p-0">
      <div class="container pb-5 pt-3 position-relative bg-white content">
        <img :src="require('@/assets/Logos/etn-cwp-dark.svg')" class="mt-4 mb-4" width="175">
        <h2 class="font-weight-bold">{{ $t('connecting_etn.receive_payments.title') }}</h2>
        <div class="mb-1">{{ $t('connecting_etn.receive_payments.copy') }}</div>
        <span v-b-modal.etn-modal class="etn blue">{{ $t('connecting_etn.receive_payments.etn') }}</span>
        <div class="d-flex flex-column flex-md-row mx-auto w-100 justify-content-center align-items-center mt-5 connecting-buttons">
          <router-link :to="{ name: 'connect-app', params: { hasApp: true } }" class="btn mr-md-3 mb-3 mb-md-0 font-weight-bold text-left d-flex align-items-center">
            {{ $t('connecting_etn.receive_payments.yes_button') }}
          </router-link>
          <router-link :to="{ name: 'connect-app', params: { hasApp: false } }" class="btn ml-md-3 font-weight-bold text-left d-flex align-items-center">
            {{ $t('connecting_etn.receive_payments.no_button') }}
          </router-link>
        </div>
      </div>
    </div>

    <ElectroneumInfo/>
  </div>
</template>

<script>
import HeroCurved from '../../components/Global/HeroCurved.vue'
import ElectroneumInfo from '../../components/Modal/ElectroneumInfo.vue'

export default {
  name: 'ConnectingEtn',
  components: {
    HeroCurved,
    ElectroneumInfo
  }
}
</script>

<style lang="scss" scoped>
.hero {
  background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.25) ), url('~@/assets/Connecting/receive-payments-banner-mobile.jpg');
  @include sm-up {
    background-image: url('~@/assets/Connecting/receive-payments-banner-small.jpg');
  }
  @include md-up {
    background-image: url('~@/assets/Connecting/receive-payments-banner-medium.jpg');
  }
  @include lg-up {
    background-image: url('~@/assets/Connecting/receive-payments-banner-large.jpg');
  }
}

.content {
  margin-top: 0;
  .blue {
    color: $brand-sky;
  }
  .connecting-buttons {
    .btn {
      background-repeat: no-repeat;
      border-radius: 12px;
      border: transparent;
      box-shadow: $connecting-btn-shadow;
      font-family: $header-font;
      font-size: 24px;
      padding: 45px 40px 45px 90px;
      white-space: pre-wrap;
      width: 100%;
      transition: all .2s ease-in-out;
      &:nth-of-type(1) {
        background-image: url('~@/assets/Connecting/yes-geo-shapes.svg');
      }
      &:nth-of-type(2) {
        background-image: url('~@/assets/Connecting/no-geo-shapes.svg');
      }
      &:hover {
          box-shadow: $connecting-btn-hover-shadow;
          transform: scale(1.025);
          transition: all .2s ease-in-out;
      }
      a {
        color: $brand-denim;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

@include md-up {
  .content {
    border-radius: 18px;
    margin-top: -5rem;
    .connecting-buttons {
      .btn {
        width: 47%;
      }
    }
  }
}

@include lg-up {
  .content {
    .connecting-buttons {
      .btn {
        width: 36%;
      }
    }
  }
}
</style>
